import React from 'react';
import { cn } from '../lib/utils';

const Button = React.forwardRef(({ className, variant, size, ...props }, ref) => {
  return (
    <button
      className={cn(
        "px-4 py-2 rounded font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
        {
          'bg-blue-500 text-white hover:bg-blue-600': variant === 'primary',
          'bg-gray-200 text-gray-800 hover:bg-gray-300': variant === 'secondary',
          'bg-transparent hover:bg-gray-100': variant === 'ghost',
          'text-sm px-3 py-1': size === 'sm',
          'text-lg px-6 py-3': size === 'lg',
        },
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

export default Button;