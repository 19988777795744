import React, { useState, useEffect } from 'react';
import './App.css';
import { Moon, Sun, Zap } from "lucide-react"
import { cn } from './lib/utils';
import Button from './components/Button';
// import Input from './components/Input';

function App() {
  const [theme, setTheme] = useState('light')
  // const [email, setEmail] = useState('')

  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    setTheme(prefersDark ? 'dark' : 'light')
  }, [])

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   // Handle email submission here
  //   console.log('Submitted email:', email)
  //   setEmail('')
  // }

  return (   
    <div className="App">
      <div className={cn(
        "min-h-screen flex flex-col relative overflow-hidden",
        theme === 'dark' ? 'bg-slate-900 text-white' : 'bg-indigo-50 text-gray-900'
      )}>
      {/* Magical Background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className={`absolute inset-0 ${theme === 'dark' ? 'bg-gradient-to-br from-indigo-900 via-purple-900 to-slate-900' : 'bg-gradient-to-br from-indigo-100 via-purple-200 to-pink-100'}`}></div>
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className={`absolute rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float ${theme === 'dark' ? 'bg-indigo-500' : 'bg-purple-300'}`}
            style={{
              width: `${Math.random() * 400 + 100}px`,
              height: `${Math.random() * 400 + 100}px`,
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 10}s`,
              animationDuration: `${Math.random() * 10 + 10}s`,
            }}
          ></div>
        ))}
      </div>

      <nav className="p-4 flex justify-between items-center relative z-10">
        <div className="flex items-center space-x-2">
          <Zap className="w-8 h-8 text-indigo-500" />
          <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-500">
            Wiztrom
          </span>
        </div>
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={toggleTheme}
          className="rounded-full p-2"
        >
          {theme === 'light' ? <Moon className="h-5 w-5" /> : <Sun className="h-5 w-5" />}
        </Button>
      </nav>

      <main className="flex-grow flex items-center justify-center px-4 relative z-10">
        <div className="max-w-3xl w-full space-y-8 text-center">
          <h1 className="text-4xl md:text-6xl font-bold">
            The Future of AI is Coming Soon
          </h1>
          <p className="text-xl md:text-2xl">
            Get ready for Wiztrom - Your personal AI assistant that will revolutionize the way you work and live.
          </p>
          {/* Form for email subscription
          <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 justify-center">
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="max-w-sm"
              required
            />
            <Button 
              type="submit" 
              variant="primary"
              className={cn(
                theme === 'dark' ? 'bg-indigo-500 hover:bg-indigo-600' : 'bg-purple-500 hover:bg-purple-600'
              )}
            >
              Notify Me
            </Button>
          </form>
          */}
        </div>
      </main>

      <footer className={cn(
        "p-4 text-center relative z-10",
        theme === 'dark' ? 'bg-slate-800 bg-opacity-50' : 'bg-indigo-200 bg-opacity-50'
      )}>
        <p>&copy; 2024 Wiztrom. All rights reserved.</p>
      </footer>

      <style jsx>{`
        @keyframes float {
          0% { transform: translateY(0px) rotate(0deg); }
           50% { transform: translateY(-20px) rotate(180deg); }
          100% { transform: translateY(0px) rotate(360deg); }
        }
        .animate-float {
          animation: float linear infinite;
        }
      `}</style>
    </div>
    </div>
  );
}

export default App;
